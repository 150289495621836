<template>
  <div :class="['spinner-layout', itemClass]">
    <div class="spinner-wrapper">
      <div class="spinner-border spinner-border-lg mr-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerComponent',
  props: {
    itemClass: {
      type: String,
      required: false,
    },
  },
}
</script>
